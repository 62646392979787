/* eslint-disable no-restricted-syntax */
import Fingerprint2 from "fingerprintjs2";
import Configs from "@/config";
import fetch from "./fetch";
import store from "../store/index";

export function isWeixin() {
  const ua = window.navigator.userAgent.toLowerCase();
  // eslint-disable-next-line eqeqeq
  if (ua.match(/MicroMessenger/i) && ua.match(/MicroMessenger/i)[0] === "micromessenger") {
    return true;
  }
  return false;
}

export function terminalType() {
  const u = navigator.userAgent;
  const isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; // g
  const isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
  if (isAndroid) {
    // 这个是安卓操作系统
    return "android";
  }
  if (isIOS) {
    // 这个是ios操作系统
    return "ios";
  }
  return "未知";
}

/**
 * 生成全局唯一标识符 uuid
 * @returns {*|string}
 */
const createUUID = () => {
  let d = new Date().getTime();
  const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
};

export default {
  getQueryString: (paraName) => {
    const url = document.location.toString();
    const arrObj = url.split("?");

    if (arrObj.length > 1) {
      const arrPara = arrObj[1].split("&");
      let arr;

      for (let i = 0; i < arrPara.length; i + 1) {
        arr = arrPara[i].split("=");
        if (arr != null && arr[0] === paraName) {
          return arr[1];
        }
      }
      return "";
    }
    return "";
  },

  loadFile(path) {
    return () => import(path);
  },
};
/**
 * 获取全局唯一标识符 uuid
 * @returns {*|string}
 */
export const readUUID = () => {
  let id = JSON.parse(localStorage.getItem("CLIENT_UUID"));

  if (!id) {
    id = createUUID();
    localStorage.setItem("CLIENT_UUID", JSON.stringify(id));
  }

  store.commit("set_user", {
    userId: id,
    userName: `游客${id.substring(0, 8)}`,
  });
  return id;
};
/**
 * 生成客户端唯一编码
 */

export const getId = () => {
  const options = {
    NOT_AVAILABLE: "not available",
    ERROR: "error",
    EXCLUDED: "excluded",
  };
  if (window.requestIdleCallback) {
    requestIdleCallback(() => {
      Fingerprint2.getV18(options, (result) => {
        store.commit("set_user", {
          userId: result,
          userName: `游客${result.substring(0, 8)}`,
        });
      });
    });
  } else {
    setTimeout(() => {
      Fingerprint2.getV18(options, (result) => {
        store.commit("set_user", {
          userId: result,
          userName: `游客${result.substring(0, 8)}`,
        });
      });
    }, 500);
  }
};

// eslint-disable-next-line func-names
export const statisticsPoint = function (options) {
  fetch({
    baseURL: Configs.pointUrl,
    method: "get",
    url: "/api/pi-point-service/point/collect",
    independent: true,
    params: {
      ...store.state.point_data,
      eventTime: new Date().getTime(),
      browserType: isWeixin() ? "微信" : "h5", // 浏览器类型
      terminalType: terminalType(), // 终端类型
      uuid: readUUID(),
      ...options,
      requestId: createUUID(),
      appId: "live-h5-static",
    },
  });
};

export const dateFormat = (data, format) => {
  const formarDate = new Date(data);
  const date = {
    "M+": formarDate.getMonth() + 1,
    "d+": formarDate.getDate(),
    "h+": formarDate.getHours(),
    "m+": formarDate.getMinutes(),
    "s+": formarDate.getSeconds(),
    "q+": Math.floor((formarDate.getMonth() + 3) / 3),
    "S+": formarDate.getMilliseconds(),
  };
  if (/(y+)/i.test(format)) {
    // eslint-disable-next-line no-param-reassign
    format = format.replace(RegExp.$1, `${formarDate.getFullYear()}`.substr(4 - RegExp.$1.length));
  }
  // const keys = Object.keys(date);
  // for (const k of keys) {
  //     if (new RegExp(`(${k})`).test(format)) {
  //         // eslint-disable-next-line no-param-reassign
  //         format = format.replace(
  //             RegExp.$1,
  //             RegExp.$1.length === 1 ? date[k] : `00${date[k]}`.substr(`${date[k]}`.length)
  //         );
  //     }
  // }
  // eslint-disable-next-line no-unused-vars
  for (const k in date) {
    if (new RegExp(`(${k})`).test(format)) {
      // eslint-disable-next-line no-param-reassign
      format = format.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? date[k] : `00${date[k]}`.substr(`${date[k]}`.length)
      );
    }
  }
  return format;
};
