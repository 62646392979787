const state = {
  info: {
    role: "VISITOR",
    userId: "",
    userName: "",
  },
};

const mutations = {
  set_user(sta, data) {
    // eslint-disable-next-line no-param-reassign
    sta.info = Object.assign(sta.info, data);
  },
};

const actions = {};

export default {
  state,
  mutations,
  actions,
};
