import Vue from "vue";
import Vuex from "vuex";
// import createPersistedState from 'vuex-persistedstate';
import modules from "./modules/index";

Vue.use(Vuex);

export default new Vuex.Store({
  modules,
  state: {
    point_data: {
      // skuCode: '00001',
      modelType: "cs",
    },
    serviceConfig: {},
  },
  mutations: {
    // init_point_data: (state, data) => {
    //     const pointData = {
    //         ...state.point_data,
    //         ...data
    //     };
    //     state.point_data = pointData;
    // },

    setConfig(state, data) {
      state.serviceConfig = data;
    },
  },
});
