export default {
  serviceChannelList: {
    "00001": {
      // 广州惠民保
      accessId: "a8855ed0-fb02-11e9-bd97-17cd8267405a",
      isShowInsured: 1,
      isShowMobile: 1,
      isShowVideo: 1,
    },
    "00002": {
      // 惠州惠民保
      accessId: "e82fd090-57bb-11ea-9f41-037831141881",
      isShowInsured: 1,
      isShowMobile: 1,
      isShowVideo: 1,
    },
    KF00003: {
      // 思派医药服务公众号 住院垫付客服
      accessId: "9a5f6c20-5dda-11ea-944a-ab15f6d2f792",
      isShowInsured: 0,
      isShowMobile: 0,
      isShowVideo: 0,
    },
    KF00004: {
      // 思派健康保 个人中心客服
      accessId: "a2b7cf40-7a57-11ea-ac95-fd46e087a170",
      isShowInsured: 0,
      isShowMobile: 0,
      isShowVideo: 0,
    },
    KF00005: {
      // 思派健康保 个人中心客服
      accessId: "db4f7080-7b09-11ea-93e9-7585eda78d18",
      isShowInsured: 0,
      isShowMobile: 0,
      isShowVideo: 0,
    },
    KF00006: {
      // 思派健康保 个人中心客服
      accessId: "3bd21fd0-7d7a-11ea-be2d-99de0ede20cb",
      isShowInsured: 0,
      isShowMobile: 0,
      isShowVideo: 0,
    },
    KF00007: {
      // 思派健康保 个人中心客服
      accessId: "e73e7160-7d7b-11ea-be2d-99de0ede20cb",
      isShowInsured: 0,
      isShowMobile: 0,
      isShowVideo: 0,
    },
    KF00008: {
      //  成都惠民保
      accessId: "65ed04b0-8081-11ea-9f80-2f1457d2dc95",
      isShowInsured: 0,
      isShowMobile: 0,
      isShowVideo: 1,
    },
    KF00009: {
      //  C端-医生详情页-预约线下 (企业用户)
      accessId: "24473bf0-8790-11ea-a605-19672cbbc43d",
      isShowInsured: 0,
      isShowMobile: 0,
      isShowVideo: 0,
    },
    KF00010: {
      // 嘉兴惠民保
      accessId: "735a06e0-b202-11ea-809b-9763415970db",
      isShowInsured: 1,
      isShowMobile: 1,
      isShowVideo: 1,
    },
  },
};
