import Fetch from "../utils/fetch";

export const getLiveingRoom = (params) =>
  Fetch({
    url: "/api/im-web/video/liveRoom",
    method: "get",
    params,
  });

export const getChannelCofing = (params) =>
  Fetch({
    url: "/api/im-web/video/channel/config",
    method: "get",
    params,
  });

export const getVideoList = (params) =>
  Fetch({
    url: "/api/im-web/video/channel/videolist",
    method: "get",
    params,
  });
