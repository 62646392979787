var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"layout"},[_c('router-view',{staticClass:"layout_container"}),_c('div',{staticClass:"drag_area",on:{"touchend":_vm.touchend,"touchmove":_vm.touchmove}},[_c('ul',{ref:"drag",staticClass:"btn_wrapper",style:({
        left: `${_vm.x}px`,
        top: `${_vm.y}px`,
      }),on:{"touchstart":_vm.touchstart,"click":function($event){$event.stopPropagation();}}},[(_vm.serviceConfig.isShowVideo)?[(_vm.$route.matched[1].name !== 'service_video')?_c('li',{staticClass:"btn_item btn_private video_btn",on:{"click":_vm.click_video_btn}},[_vm._v(" 视频客服 实时回答 ")]):_c('li',{staticClass:"btn_item btn_private robot_btn",on:{"click":_vm.click_robot_btn}},[_vm._v(" 在线客服 实时回答 ")])]:_vm._e(),(
          _vm.serviceConfig.isShowMobile ||
          _vm.$route.matched[1].name == 'service_video'
        )?_c('li',{staticClass:"btn_item phone_btn",on:{"click":_vm.click_phone_btn}},[_vm._v(" 电话客服 ")]):_vm._e(),(_vm.serviceConfig.isShowInsured)?_c('li',{staticClass:"btn_item project_btn",on:{"click":_vm.click_project_btn}},[_vm._v(" 立即投保 ")]):_vm._e()],2)]),_c('med-dialog',{attrs:{"show":_vm.dialog_show},on:{"update:show":function($event){_vm.dialog_show=$event},"confirm":_vm.confirm}},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_vm._v(" 客服电话："+_vm._s(_vm.serviceConfig.serviceMobile || "400 001 3666")+" ")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }