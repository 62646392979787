import { getVideoList } from "@/api";

const state = {
  room_info: {
    roomId: "",
    appId: "",
    phoneConf: 0,
    roomStatus: 1,
  },
  chat_client: null,
  person_count: 0,
  active_video: null,
  video_list: [],
};

const mutations = {
  change_person_count(sta, data) {
    // eslint-disable-next-line no-param-reassign
    sta.person_count = data * 3 + 51;
  },
  set_room_info(sta, data) {
    const info = data || {
      roomId: "",
      appId: "",
      phoneConf: 0,
      roomStatus: 1,
    };

    // info.appId = 'ba12d80561894bc4b348c20e428c7a87';
    // eslint-disable-next-line no-param-reassign
    sta.room_info = info;
  },
  save_client(sta, client) {
    // eslint-disable-next-line no-param-reassign
    sta.chat_client = client;
  },
  // eslint-disable-next-line no-shadow
  setActiveVideo(state, data) {
    state.active_video = data;
  },
  // eslint-disable-next-line no-shadow
  saveVideoList(state, data) {
    state.video_list = data;
  },
};

const actions = {
  // eslint-disable-next-line no-shadow
  getVideoList({ rootState, commit }) {
    // console.log(state);
    getVideoList({
      userId: rootState.users.info.userId,
      channelConfigId: rootState.serviceConfig.id,
    }).then((res) => {
      const list = res.data;
      if (list.length) {
        commit("saveVideoList", list);
        commit("setActiveVideo", list[0]);
      }
    });
  },
  // eslint-disable-next-line no-shadow
  nextVideo({ state, commit }) {
    let activeIndex = 0;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < state.video_list.length; i++) {
      const element = state.video_list[i];
      if (element.id === state.active_video.id) {
        activeIndex = i;
      }
    }
    if (activeIndex < state.video_list.length - 1) {
      commit("setActiveVideo", state.video_list[activeIndex + 1]);
    } else {
      commit("setActiveVideo", state.video_list[0]);
    }
  },
};

export default {
  state,
  mutations,
  actions,
};
