<template>
  <div v-if="show" class="dialog">
    <div class="dialog_box">
      <img
        class="close_btn"
        src="../assets/images/btn_delete_center_grey@2x.png"
        @click="close"
      >

      <div class="dialog_header">
        <slot name="header">
          客服电话：400 001 3666
        </slot>
      </div>
      <div class="dialog_footer">
        <button class="btn primary" @click="confirm">
          拨打
        </button>
        <button class="btn plain" @click="cancel">
          再想想
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    close() {
      this.$emit("update:show", false);
      this.$emit("closed");
    },
    confirm() {
      this.$emit("confirm");
    },
    cancel() {
      this.$emit("update:show", false);
      this.$emit("cancel");
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}

.dialog_box {
  width: 260px;
  // height: 194px;
  background: $color-white;
  border-radius: 8px;
  position: relative;
  padding: 33px 25px 25px;
  text-align: center;
}

.dialog_header {
  font-family: PingFang SC;
  font-weight: 500;
  color: $color-black;
  margin-bottom: 33px;
}

.close_btn {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 8px;
  right: 8px;
}

.btn {
  display: block;
  width: 123px;
  height: 40px;
  margin: 0 auto;
  margin-bottom: 12px;
  border-radius: 20px;
  border: none;
  outline: none;
}
.btn:nth-last-child(1) {
  margin-bottom: 0;
}

.btn.primary {
  background: $theme;
  color: $color-white;
}

.btn.plain {
  background: $color-white;
  color: $theme;
  border: 1px solid $theme;
}
</style>
