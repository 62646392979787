import Axios from "axios";
// import Vue from 'vue';
import Configs from "@/config";

// const App = new Vue();

const fetch = async (config) => {
  const { method, params, independent, url, timeout, baseURL, ...other } = config;
  const headers = {};
  // 创建axios实例
  const axiosIns = Axios.create();
  let options = {
    headers,
    baseURL: baseURL || Configs.baseUrl,
    url,
    ...other,
    timeout: timeout || 1 * 60 * 1000,
  };

  if (method === "get") {
    options = {
      ...options,
      method: "get",
      params,
    };
  } else {
    options = {
      ...options,
      method: "post",
      data: params,
    };
  }

  const response = await axiosIns(options)
    .then((res) => {
      // const { code, message } = res.data;
      // independent 存在做单独异常处理，其余走统一业务处理
      if (independent) return res.data;

      // if (code !== '200') {
      //     // code不为0视为错误
      //     App.$message({
      //         message,
      //         type: 'error',
      //     });
      //     return false;
      // }
      return res.data;
    })
    .catch((res) => {
      console.log(res);
      return false;
    });

  return response;
};

export default fetch;
