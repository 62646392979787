<template>
  <div class="service-wrapper">
    <iframe
      ref="serviceIframe"
      class="serviceIframe"
      :src="iframe"
      frameborder="0"
      width="100%"
      height="100%"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import Configs from "../config";

function scrollBottom(o) {
  if (o.data === "scrollBody") {
    setTimeout(() => {
      document.body.scrollTop = document.body.scrollHeight;
      window.scroll(0, 0);
    }, 10);
  }
}

// const accessId = {
//     '00001': 'a8855ed0-fb02-11e9-bd97-17cd8267405a', // 广州惠民保
//     '00002': 'e82fd090-57bb-11ea-9f41-037831141881', // 惠州惠民保
//     KF00003: '9a5f6c20-5dda-11ea-944a-ab15f6d2f792', // 思派医药服务公众号 住院垫付客服
//     KF00004: 'a2b7cf40-7a57-11ea-ac95-fd46e087a170', // 思派健康保 个人中心客服
//     KF00005: 'db4f7080-7b09-11ea-93e9-7585eda78d18' // 思派健康保 个人中心客服
// };

export default {
  data() {
    return {
      serviceChannelList: Configs.serviceChannelList,
      iframe: "",
      fromUrl: this.$route.query.sourceUrl,
      urlTitle: "在线客服",
    };
  },
  computed: mapState({
    users: (state) => state.users.info,
    serviceConfig: "serviceConfig",
    // point_data: 'point_data'
  }),
  watch: {
    serviceConfig: {
      handler(val) {
        if (val) {
          console.log(val);
          window.statisticsPoint({
            pageId: this.$route.meta.pageId,
            eventType: "init",
          });

          if (this.$route.query.nickName) {
            // 指定客服聊天昵称
            const otherParams = {
              nickName: this.$route.query.nickName,
            };
            this.iframe = `https://webchat.7moor.com/wapchat.html?accessId=${
              val.accessId
            }&fromUrl=${this.sourceUrl}&urlTitle=${this.urlTitle}&clientId=${
              this.users.userId
            }&otherParams=${JSON.stringify(otherParams)}`;

            console.log(this.iframe);
          } else {
            this.iframe = `https://webchat.7moor.com/wapchat.html?accessId=${val.accessId}&&clientId=${this.users.userId}fromUrl=${this.sourceUrl}&urlTitle=${this.urlTitle}`;
          }
        }
      },
      immediate: true,
    },
    // point_data: {
    //     handler(val) {
    //         if (val.referrer) {
    //             // 保证来源被统计到
    // window.statisticsPoint({
    //     pageId: this.$route.meta.pageId,
    //     eventType: 'init'
    // });
    //     const serviceChannel = val.serviceChannel || val.skuCode || '00001';
    //     if (this.$route.query.nickName) {
    //         // 指定客服聊天昵称
    //         const otherParams = {
    //             nickName: this.$route.query.nickName
    //         };
    //         this.iframe = `https://webchat.7moor.com/wapchat.html?accessId=${
    //             this.serviceChannelList[serviceChannel].accessId
    //         }&fromUrl=${this.fromUrl}&urlTitle=${this.urlTitle}&clientId=${
    //             this.users.userId
    //         }&otherParams=${JSON.stringify(otherParams)}`;
    //     } else {
    //         this.iframe = `https://webchat.7moor.com/wapchat.html?accessId=${
    //             this.serviceChannelList[serviceChannel].accessId
    //         }&fromUrl=${this.fromUrl}&urlTitle=${this.urlTitle}`;
    //     }
    // }
    //     },
    //     immediate: true
    // }
    // 'users.userId': {
    //     handler(val) {
    //         if (val) {
    //             this.iframe = `https://webchat.7moor.com/wapchat.html?accessId=a8855ed0-fb02-11e9-bd97-17cd8267405a&fromUrl=${this.fromUrl}&urlTitle=${this.urlTitle}`;
    //         }
    //     },
    //     immediate: true
    // }
  },
  created() {
    if (window.addEventListener) {
      window.addEventListener("message", scrollBottom);
    } else {
      window.attachEvent("onmessage", scrollBottom);
    }
  },
};
</script>

<style lang="scss">
.service-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
</style>
