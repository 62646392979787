<template>
  <div class="live_wrapper">
    <div :id="videoClass" class="video" :style="{ height: `${videoHeight}px` }">
      <span v-if="!videoShow" class="video_status">
        <!-- <span v-if="options.fullStatus !== 1 && videoState !== ''">
                    {{ videoState == 1 ? '视频加载中' :
                        videoState == 0 ?
                            '直播已结束，请在9：00~21：00拨打热线电话进行咨询'
                            : '本次直播已结束，感谢您的收看' }}
                </span>
                <span v-else>
                    直播间已满，请您稍后再查看，
                    或点击右侧电话客服或人工客服进行咨询
                </span> -->

        <span v-if="videoState === '' || videoState === 4">
          直播间已满，请您稍后再查看， 或点击右侧电话客服或人工客服进行咨询
        </span>

        <span v-else-if="videoState === 1"> 视频加载中 </span>
        <span v-else-if="videoState === 0">
          直播已结束，请在9：00~21：00拨打热线电话进行咨询
        </span>
        <span v-else> 本次直播已结束，感谢您的收看 </span>
      </span>
      <ul v-else-if="videoState === 1" class="video_info">
        <li>
          <img
            class="video_icon"
            src="../../assets/images/icon-person@2x.png"
          />
          <span>{{ person_count }}</span>
        </li>
      </ul>
      <video
        v-if="active_video"
        class="video"
        :src="active_video.videoUrl"
        controls
        autoplay
        @ended="videoEnd"
      />
    </div>
    <ul v-if="videoState" class="nav_bar">
      <li
        class="nav_item"
        :class="{ active: $route.name == 'video_notice' }"
        @click="change_route('/video/notice', 'B0004')"
      >
        公告
      </li>
      <li
        class="nav_item"
        :class="{ active: $route.name == 'video_chat' }"
        @click="change_route('/video/chat', 'B0005')"
      >
        聊天
      </li>
    </ul>

    <ul v-else class="nav_bar video_nav">
      <li class="nav_item active">相关视频</li>
    </ul>

    <div
      class="nav_content"
      :class="{
        not_weixin: !isWeixin,
        white_background: $route.name === 'video_product_list',
      }"
    >
      <keep-alive>
        <router-view />
      </keep-alive>
    </div>
    <remote-script src="/file/agora-rtc.js" @load="sdkLoaded" />
    <remote-script src="/file/agora-rts.js" @load="sdkLoaded" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { Toast, Dialog } from "vant";
import { getLiveingRoom } from "@/api";
import { isWeixin } from "../../utils/index";

export default {
  components: {
    "remote-script": {
      name: "remoteScript",
      render(createElement) {
        const self = this;
        return createElement("script", {
          attrs: {
            type: "text/javascript",
            src: this.src,
          },
          on: {
            load(event) {
              console.log("load");
              self.$emit("load", event);
            },
            error(event) {
              console.log("error", event);
              self.$emit("error", event);
            },
            readystatechange(event) {
              if (this.readyState === "complete") {
                self.$emit("load", event);
              }
            },
          },
        });
      },
      props: {
        src: {
          type: String,
          required: true,
        },
      },
    },
  },
  data() {
    return {
      client: "", // 客户端对象
      videoShow: false,
      videoState: "", // 0:未开播 1:正在直播 3:已结束
      remoteStream: {},
      role: "audience",
      videoId: "",
      videoClass: "",
      AgoraRTC: "",
      AgoraRTS: "",
      // selectedNav: 1,
      // replyContent: '',
      timeOutEvent: "",
      videoRatio: 16 / 9,
      videoHeight: "",
      isWeixin: isWeixin(),
      initVideo: false,
      fileCount: 0,
      sdkReady: false,
    };
  },
  computed: mapState({
    options: (state) => state.live.room_info,
    user: (state) => state.users.info,
    person_count: (state) => state.live.person_count,
    serviceConfig: "serviceConfig",
    active_video: (state) => state.live.active_video,
    // point_data: 'point_data',
  }),
  watch: {
    active_video: {
      handler(val) {
        console.log(val);
        if (val) {
          this.videoShow = true;
        }
      },
      immediate: true,
    },
    serviceConfig: {
      handler(val) {
        if (val.channelId) {
          this.get_live_info(val.id);
        }
      },
      immediate: true,
    },
    // point_data: {
    //     handler(val) {
    //         if (val.referrer) {
    //             // 保证来源被统计到
    //             window.statisticsPoint({
    //                 pageId: this.$route.meta.pageId,
    //                 eventType: 'init',
    //             });
    //         }
    //     },
    //     immediate: true,
    // },
    // 'user.userId': {
    //     handler(val) {
    //         if (val) {
    //             this.get_live_info();
    //         }
    //     },
    //     immediate: true,
    // },
    options: {
      handler(val) {
        this.videoState = val.roomStatus || 0;
        if (this.videoState !== 1) {
          this.$router.replace("/video/notice");
        }
        if (val.roomId && this.sdkReady) {
          // 确保sdk 加载完成之后初始化视频
          this.initAudienceClient();
        } else if (this.client) {
          this.client.unsubscribe();
          this.client.leave(() => {
            console.log("client leaves channel");
          });
        }
      },
    },
    $route(val) {
      if (val.name === "video_product_list") {
        clearTimeout(this.timeOutEvent);
      }
    },
  },
  created() {
    this.timeOutEvent = setInterval(() => {
      Toast("若没有解决您的问题，请点击人工客服或者电话客服进行咨询");
    }, 2 * 1000 * 60);
  },
  mounted() {
    this.calculateScreenRatio();
  },
  beforeDestroy() {
    clearTimeout(this.timeOutEvent);
    this.$store.commit("save_client", null);
    this.$store.commit("set_room_info", null);
    if (this.client) {
      this.client.unsubscribe(this.remoteStream);
      this.leave();
    }
  },
  methods: {
    sdkLoaded() {
      this.fileCount += 1;
      if (this.fileCount >= 2) {
        this.sdkReady = true;
        if (this.options.roomId) {
          this.initAudienceClient();
        }
      }
    },
    videoEnd() {
      this.$store.dispatch("nextVideo");
    },
    get_live_info(id) {
      getLiveingRoom({
        channelConfigId: id,
        userId: this.user.userId,
      }).then((res) => {
        if (res.code === "0") {
          this.$store.commit("set_room_info", res.data);
        } else if (res.code === "1") {
          // 被移除直播间
          Toast.fail("观看人数太多了，请稍后进入");
          this.$router.go(-1);
        } else if (res.code === "2") {
          // 直播结束
          this.videoState = 0;
          if (
            this.serviceConfig.playType &&
            this.$route.path !== "/video/product_list"
          ) {
            this.$router.replace({
              path: "/video/product_list",
              query: this.$route.query,
            });
          }
        } else if (res.code === "3") {
          // 直播间人数已满
          this.videoState = 4;
        }
      });
      // this.$fetch({
      //     url: '/api/im-web/room/get/free',
      //     method: 'get',
      //     params: {
      //         userId: this.user.userId,
      //     },
      // })
      //     .then((res) => {
      //         if (res.data.intoUserStatus === 2) {
      //             // 被移除用户
      //             Toast.fail('观看人数太多了，请稍后进入');
      //             this.$router.replace('/');
      //             return;
      //         }
      //         this.$store.commit('set_room_info', res.data);
      //     });
    },

    change_route(path, buttonId) {
      window.statisticsPoint({
        pageId: this.$route.meta.pageId,
        eventType: "click",
        buttonId,
      });
      this.$router.replace(path);
    },

    /**
     * 针对h5应用，使用AgoraRTS代理AgoraRTC，兼容性更好
     */
    proxyAgoraRTC() {
      // eslint-disable-next-line no-undef
      this.AgoraRTC = AgoraRTC;
      // eslint-disable-next-line no-undef
      this.AgoraRTS = AgoraRTS;

      // 允许上传视频日志
      this.AgoraRTC.Logger.enableLogUpload();

      // 创建客户端
      this.client = this.AgoraRTC.createClient({
        mode: "live",
        codec: "h264",
      });
      this.AgoraRTC.Logger.setLogLevel(this.AgoraRTC.Logger.NONE);

      this.AgoraRTS.init(this.AgoraRTC, {
        wasmDecoderPath: "/file/agora-rts.wasm",
        asmDecoderPath: "/file/agora-rts.asm",
      }).catch((e) => {
        if (e === "LOAD_DECODER_FAILED") {
          console.log("加载解码器失败！");
          const { roomId, agoraUid } = this.options;
          const { userId } = this.user;
          this.$fetch({
            url: "/api/im-web/record/video_error",
            method: "post",
            params: {
              agoraUid,
              errorType: "LOAD_DECODER_FAILED",
              uid: userId,
              roomId,
              ua: navigator.userAgent,
            },
          });

          Dialog.alert({
            title: "提示",
            message: "获取超时，请检查网络，刷新试试",
            confirmButtonText: "刷新",
          })
            .then(() => {
              window.location.reload();
            })
            .catch(() => {
              // on cancel
            });
        }
      });
      this.AgoraRTS.proxy(this.client);

      this.client.enableAudioVolumeIndicator();

      if (!this.AgoraRTS.checkSystemRequirements()) {
        Toast.fail("您的浏览器不支持 RTS！");
      } else {
        console.log("check success");
      }
    },

    /**
     * 初始化视频本地用户端
     */
    initAudienceClient() {
      console.log("初始化视频流");
      if (this.initVideo) return; // 防止初始化2次视频
      this.initVideo = true;
      this.proxyAgoraRTC();
      this.streamListener();
      const { appId, roomId, agoraUid, agoraToken } = this.options;

      // 初始化客户端
      this.client.init(appId, () => {
        console.log("初始化客户端成功");
        // 设置角色为观众
        this.client.setClientRole(this.role);

        // 加入直播间
        this.client.join(
          agoraToken,
          roomId,
          agoraUid,
          (uid) => {
            console.log(`用户 ${uid} 成功进入直播间`);
            if (this.$route.matched[1].name !== "service_video") {
              // 如果初始化成功之后，已经退出页面，则直接离开直播间
              this.leave();
            }
          },
          (err) => {
            console.log(`进入直播间失败：${err}`);
          }
        );
      });

      this.client.on("peer-leave", () => {
        this.leave();
      });
    },

    /**
     * 监听远端直播流事件
     */
    streamListener() {
      // 监听远端视频流直播订阅成功，开始播放
      this.client.on("stream-subscribed", (ev) => {
        const videoId = ev.stream.getId();
        this.remoteStream = ev.stream;
        this.videoClass = `video_${videoId}`;

        this.$nextTick(() => {
          this.videoShow = true;
          this.remoteStream.play(this.videoClass);
        });
      });

      // 注册监听事件
      // 监听远端视频流直播加入
      this.client.on("stream-added", (ev) => {
        this.client.subscribe(
          ev.stream,
          { video: true, audio: true },
          (err) => {
            console.log(`监听失败：${err}`);
          }
        );
      });

      // 监听异常
      this.client.on("exception", (e) => {
        console.log("exception: ", e.code, e.msg, e.uid);
      });
    },

    /**
     * 根据视频分辨率和窗口可视宽度计算视频容器的高度
     */
    calculateScreenRatio() {
      const { videoRatio } = this;
      const { clientWidth } = document.body;
      this.videoHeight = clientWidth / videoRatio;
    },
    // 离开当前直播间
    leave() {
      this.client.leave();
      if (
        this.$store.state.live.chat_client &&
        this.$store.state.live.chat_client.close
      ) {
        this.$store.state.live.chat_client.close();
      }
      this.$store.commit("save_client", null);
      this.videoState = 3;
      this.videoShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.live_wrapper {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
}

.video {
  height: 210.94px;
  background: url("~@/assets/images/video_back.png");
  background-size: 100%;
  overflow: hidden;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  position: relative;
}
.video_status {
  text-align: center;
  padding: 0 30px;
  line-height: 28px;
}
.video_info {
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 999;
  font-size: 12px;
  text-align: center;

  li {
    display: flex;
    flex-direction: column;
  }

  .video_icon {
    width: 17px;
    height: 17px;
    margin-bottom: 5px;
  }
}

.nav_bar {
  background: #fff;
  height: 45px;
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  padding: 0 82px;
  box-shadow: 0px -1px 8px 0px rgba(68, 134, 251, 0.1);
  font-weight: 500;
  position: relative;
  z-index: 999;

  &.video_nav {
    padding: 0 51px;
    .nav_item.active {
      border: none;
    }
  }
  .nav_item {
    line-height: 45px;
    color: $color-black;

    &.active {
      color: $theme;
      border-bottom: 3px solid #f36218;
    }
  }
}

.nav_content {
  height: calc(100vh - 210.94px - 45px);
  background: #f5f7fa;
  font-size: 14px;
  color: $color-black;
  overflow-y: auto;
  font-family: PingFang SC;
  -webkit-overflow-scrolling: touch;
}

.not_weixin {
  padding-bottom: 45px;
}

.video {
  max-height: 100%;
  max-width: 100%;
}

.white_background {
  background: #fff;
}
</style>
