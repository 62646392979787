import base from "./base";
import dev from "./config.dev";
import prod from "./config.prod";
import test from "./config.test";

let config = {};
switch (process.env.NODE_ENV) {
  case "production":
    if (process.env.VUE_APP_ISUAT === "true") {
      config = test;
    } else {
      config = prod;
    }
    break;
  default:
    config = dev;
}

const configs = config;
export default Object.assign(base, configs);
