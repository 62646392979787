<template>
  <div class="layout">
    <router-view class="layout_container" />
    <div class="drag_area" @touchend="touchend" @touchmove="touchmove">
      <ul
        ref="drag"
        class="btn_wrapper"
        :style="{
          left: `${x}px`,
          top: `${y}px`,
        }"
        @touchstart="touchstart"
        @click.stop
      >
        <template v-if="serviceConfig.isShowVideo">
          <li
            v-if="$route.matched[1].name !== 'service_video'"
            class="btn_item btn_private video_btn"
            @click="click_video_btn"
          >
            <!-- <span class="text">视频客服</span>
                        <span class="text">实时回答</span> -->
            视频客服 实时回答
          </li>
          <li
            v-else
            class="btn_item btn_private robot_btn"
            @click="click_robot_btn"
          >
            <!-- <span class="text">在线客服</span>
                        <span class="text">实时回答</span> -->
            在线客服 实时回答
          </li>
        </template>

        <li
          v-if="
            serviceConfig.isShowMobile ||
            $route.matched[1].name == 'service_video'
          "
          class="btn_item phone_btn"
          @click="click_phone_btn"
        >
          电话客服
        </li>
        <li
          v-if="serviceConfig.isShowInsured"
          class="btn_item project_btn"
          @click="click_project_btn"
        >
          立即投保
        </li>
      </ul>
    </div>
    <med-dialog :show.sync="dialog_show" @confirm="confirm">
      <div slot="header">
        客服电话：{{ serviceConfig.serviceMobile || "400 001 3666" }}
      </div>
    </med-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Configs from "@/config";
import { readUUID, terminalType } from "@/utils/index";
import { getChannelCofing } from "@/api";

readUUID();

export default {
  data() {
    return {
      // room_info: {},
      dialog_show: false,
      show: false,
      animated: false,
      dragWidth: 0,
      dragHeight: 0,
      x: 100,
      y: 500,
      oldX: 0,
      oldY: 0,
      isDrop: false,
      rangeX: 0,
      rangeY: 0,
      moveCount: 0, // 兼容android

      is_click: false,
      timeOutEvent: "",
      phoneConf: 1,
      videoConf: 1,
      isShowBtn: false,
      showVideoBtn: false,
      showPhoneBtn: false,
      serviceInfo: {},
    };
  },
  computed: mapState({
    serviceConfig: "serviceConfig",
    user: (state) => state.users.info,
  }),
  watch: {
    "user.userId": {
      handler(val) {
        if (val) {
          this.init();
        }
      },
      immediate: true,
    },
    $route() {
      this.$nextTick(() => {
        this.init_service_position();
      });
    },
  },
  created() {
    this.init();
  },
  mounted() {
    window.onresize = () => {
      this.init_service_position();
    };
  },
  methods: {
    init() {
      // 获取当前客服渠道详情
      const sourceUrl = decodeURIComponent(this.$route.query.sourceUrl);
      const { serviceChannel } = this.$route.query;
      if (serviceChannel.indexOf("000") !== -1) {
        // 客服的旧渠道
        this.$store.commit("setConfig", {
          ...Configs.serviceChannelList[serviceChannel],
          fromUrl: sourceUrl,
          sourceUrl,
        });

        return;
      }
      getChannelCofing({
        channelId: serviceChannel,
      }).then((res) => {
        this.$store.commit("setConfig", {
          ...res.data,
          sourceUrl,
        });

        this.$nextTick(() => {
          this.init_service_position();
        });
      });
    },
    click_project_btn() {
      window.statisticsPoint({
        pageId: this.$route.meta.pageId,
        eventType: "click",
        buttonId: "B0003",
      });
      window.location.href = this.serviceConfig.sourceUrl;
    },
    click_video_btn() {
      window.statisticsPoint({
        pageId: this.$route.meta.pageId,
        eventType: "click",
        buttonId: "B0001",
      });
      this.$router.push({
        path: "/video",
      });
    },
    click_robot_btn() {
      window.statisticsPoint({
        pageId: this.$route.meta.pageId,
        eventType: "click",
        buttonId: "B0008",
      });
      this.$router.push({
        path: "/",
      });
    },
    click_phone_btn() {
      window.statisticsPoint({
        pageId: this.$route.meta.pageId,
        eventType: "click",
        buttonId: "B0002",
      });
      this.dialog_show = true;
    },
    touchstart(e) {
      console.log(e);
      this.isDrop = true;
      this.is_click = true;
      this.moveCount = 0;
      this.timeOutEvent = setTimeout(() => {
        this.is_click = false;
      }, 600);
      e.preventDefault();
    },

    touchmove(e) {
      if (!this.isDrop) return;
      if (terminalType() === "android") {
        this.moveCount += 1;
        if (this.moveCount > 3) {
          clearTimeout(this.timeOutEvent);
          this.is_click = false;
        }
      } else {
        clearTimeout(this.timeOutEvent);
        this.is_click = false;
      }
      const { changedTouches } = e;
      const TouchList = changedTouches[0];
      if (this.rangeX === 0 && this.rangeY === 0) {
        this.rangeY = TouchList.clientY - this.y;
      }
      let clientX = TouchList.clientX - this.rangeX;
      let clientY = TouchList.clientY - this.rangeY;
      if (clientY < 5) {
        clientY = 5;
      }
      if (clientY > this.bodyHeight - this.dragHeight - 80) {
        clientY = this.bodyHeight - this.dragHeight - 80;
      }

      if (clientX < 20) {
        clientX = 20;
      }
      if (clientX > this.bodyWidth - this.dragWidth - 20) {
        clientX = this.bodyWidth - this.dragWidth - 20;
      }
      this.x = clientX;
      this.y = clientY;
      e.preventDefault();
    },
    touchend(e) {
      clearTimeout(this.timeOutEvent);
      this.isDrop = false;
      this.rangeX = 0;
      this.rangeY = 0;

      console.log(this.bodyWidth, this.x);
      if (this.x > this.bodyWidth / 2 - this.dragWidth / 2) {
        this.x = this.bodyWidth - this.dragWidth - 20;
      } else {
        this.x = 20;
      }

      if (this.is_click) {
        if (e.target.className.indexOf("project_btn") !== -1) {
          // 点击购买保险按钮
          this.click_project_btn();
        }
        if (e.target.className.indexOf("video_btn") !== -1) {
          // 点击视频按钮
          this.click_video_btn();
        }
        if (e.target.className.indexOf("robot_btn") !== -1) {
          // 点击人工客服按钮
          this.click_robot_btn();
        }
        if (e.target.className.indexOf("phone_btn") !== -1) {
          // 点击电话按钮
          this.click_phone_btn();
        }
      }

      e.preventDefault();
    },

    init_service_position() {
      this.initWidth = this.$refs.drag.clientWidth; // 初始化宽度
      this.dragWidth = this.$refs.drag.clientWidth; // 当前宽度
      this.dragHeight = this.$refs.drag.clientHeight; // 当前高度
      this.bodyWidth = document.body.clientWidth;
      this.bodyHeight = document.body.clientHeight;
      this.x = this.bodyWidth - this.dragWidth - 20;
      this.y = this.bodyHeight - this.dragHeight - 80;
    },

    // bths_change() {
    //     console.log('bth container resize');
    // },
    confirm() {
      window.location.href = `tel:${this.serviceConfig.serviceMobile}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.layout {
  width: 100%;
  height: 100%;
}
.layout_container {
  height: 100%;
}
.btn_wrapper {
  position: absolute;
  // right: 15px;
  // bottom: 68px;
  z-index: 999;
  width: 62px;
}
.btn_item {
  margin-bottom: 14px;
  padding: 0 11px;
  width: 62px;
  height: 62px;
  background: url("~@/assets/images/btn-suspend-normal.png") no-repeat;
  background-size: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  text-align: center;
  color: #fff;
  line-height: 19px;
  font-size: 15px;
  box-shadow: 0px 1px 9px 0px rgba(249, 159, 91, 0.28);
  border-radius: 50%;
}
.btn_private {
  display: block;
  padding: 46px 5px 0;
  width: 62px;
  height: 89px;
  background: url("~@/assets/images/btn-suspend-private.png") no-repeat;
  background-size: 100%;
  font-size: 12px;
  line-height: 16px;
  box-shadow: none;
  border-radius: 0;
  // transform: scale(0.917);

  // .text {
  //     transform: scale(0.917);
  //     display: block;
  // }
}
</style>
