import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@/pages/layout";
import Index from "@/pages/index";
import Vdieo from "@/pages/video/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "layout",
    component: Layout,
    children: [
      {
        path: "/index",
        alias: "/",
        name: "service_index",
        component: Index,
        meta: {
          pageId: "P0010",
          title: "在线客服",
        },
      },
      {
        path: "/video",
        component: Vdieo,
        name: "service_video",
        meta: {
          pageId: "P0011",
          title: "视频客服",
        },
        children: [
          {
            path: "notice",
            name: "video_notice",
            component: () => import("@/pages/video/notice"),
            meta: {
              pageId: "P0011",
              buttonId: "B0001",
              replace: true,
              title: "视频客服",
            },
          },
          {
            path: "chat",
            name: "video_chat",
            alias: "/",
            component: () => import("@/pages/video/chat"),
            meta: {
              pageId: "P0011",
              buttonId: "B0002",
              replace: true,
              title: "视频客服",
            },
          },
          {
            path: "product_list",
            name: "video_product_list",
            component: () => import("@/pages/video/product-list"),
            meta: {
              pageId: "P0011",
              buttonId: "B0003",
              replace: true,
              title: "视频客服",
            },
          },
          // {
          //     path: "product_detail/:id",
          //     name: "video_product_detail",
          //     component: () => import("@/pages/video/product-detail"),
          //     meta: {
          //         pageId: "P0011",
          //         buttonId: "B0001",
          //         replace: true,
          //         title: "视频客服"
          //     }
          // }
        ],
      },
    ],
  },
  // {
  //     path: "/ci",
  //     name: "ci",
  //     component: () => import("@/pages/ei/index"),
  //     meta: {
  //         title: "企业投保介绍页"
  //     }
  // }
];

const router = new VueRouter({
  mode: "history",
  routes,
});
router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  if (Object.keys(from.query).length && !Object.keys(to.query).length) {
    next({
      path: to.path,
      query: from.query,
      replace: from.meta.replace,
    });
  } else {
    next();
  }
});

export default router;
