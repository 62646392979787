import Vue from "vue";
import VConsole from "vconsole";
import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";
import fetch from "@/utils/fetch";
import router from "./router";
import store from "./store";
import "lib-flexible/flexible";
import "./assets/styles/base.scss";
import { statisticsPoint } from "./utils/index";
import components from "./components/index";
import { fileName } from "./filter";

Vue.use(components);

Vue.filter("fileName", fileName);

if (process.env.NODE_ENV !== "production") {
  // eslint-disable-next-line no-new
  new VConsole();
} else {
  Sentry.init({
    dsn: "https://3c4bc1f908bb49d6af597c1c1b25cbc1@sentry.medbanks.cn/3",
    integrations: [new Integrations.Vue({ Vue, attachProps: true })],
  });
}

window.statisticsPoint = statisticsPoint;

Vue.prototype.$fetch = fetch;

new Vue({
  router,
  store,
}).$mount("#app"); // 手动挂载
